import React, { useState } from 'react';
import { INewSubscription, SubscriptionPlan } from 'interfaces/userInterface';
import { subscriptionsController } from 'controllers';
import { AppSearch } from 'components/apps/AppSearch';
import { useSnackbar } from 'notistack';
import { IApp } from 'interfaces/appInterface';
import { InputField, SelectorField } from 'components/generic';
import { LabelValue } from 'interfaces/generic';
import { AppList } from 'components/apps';
import styles from './AddSubscription.module.scss';
import { currencyOptions } from 'utils/constants';
import { Button } from 'components/generic/Button';

interface AddSubscriptionProps {
  reload?: () => void;
  presetApp?: IApp;
  setMode: (mode: boolean) => void;
  subscription?: INewSubscription & { app: IApp };
  currency?: string;
}

const billingIntervals = {
  month: { value: 'month', label: 'Month' },
  year: { value: 'year', label: 'Year' },
};

export const AddSubscription = ({
  reload,
  setMode,
  presetApp,
  subscription,
  currency = 'USD',
}: AddSubscriptionProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedApp, setSelectedApp] = useState<IApp | undefined>(presetApp || subscription?.app);

  const [subscriptionData, setSubscriptionData] = useState<INewSubscription>({
    appId: presetApp?.uuid || subscription?.app?.uuid || subscription?.appId || '',
    cost: subscription?.cost || 0,
    currency: subscription?.currency || { value: currency, label: currency },
    interval: subscription?.interval || 'month',
    plan: subscription?.plan || SubscriptionPlan.Paid,
    note: subscription?.note || '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (name === 'cost') {
      // Remove any non-numeric characters except decimal point
      const sanitizedValue = value.replace(/[^0-9.]/g, '');

      // Ensure only one decimal point
      const parts = sanitizedValue.split('.');
      const formattedValue = parts.length > 2 ? `${parts[0]}.${parts[1]}` : sanitizedValue;

      // Limit to 2 decimal places
      const finalValue = formattedValue.includes('.')
        ? formattedValue.slice(0, formattedValue.indexOf('.') + 3)
        : formattedValue;

      setSubscriptionData((s) => ({
        ...s,
        cost: finalValue === '' ? undefined : Number(finalValue),
      }));
    } else {
      setSubscriptionData((s) => ({ ...s, [name]: value }));
    }
  };

  const addSubscription = async () => {
    try {
      await subscriptionsController.addSubscription({
        ...subscriptionData,
        currency: subscriptionData.currency?.value,
      });

      if (reload) reload();
      enqueueSnackbar('Subscription added', { variant: 'success' });

      // Reset form state
      setSelectedApp(undefined);
      setSubscriptionData({
        appId: '',
        cost: 0,
        currency: { value: currency, label: currency },
        interval: 'month',
        plan: SubscriptionPlan.Paid,
        note: '',
      });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
    setMode(false);
  };

  const handleSetSubscriptionApp = (app?: IApp) => {
    setSubscriptionData((s) => ({ ...s, appId: app?.uuid || '' }));
    setSelectedApp(app);
  };

  return (
    <div className={styles.addSubscription}>
      <form onSubmit={(e) => e.preventDefault()} className={styles.form}>
        <div className={styles.formGroup}>
          <label>App Name</label>
          {presetApp ? null : selectedApp ? (
            <AppList
              deleteApp={() => handleSetSubscriptionApp(undefined)}
              owned
              apps={selectedApp ? [selectedApp] : []}
            />
          ) : (
            <AppSearch addApp existingAppIds={[]} saveApp={handleSetSubscriptionApp} />
          )}
        </div>

        {subscriptionData.plan === SubscriptionPlan.Paid && (
          <div className={styles.formGroup}>
            <label>Amount</label>
            <InputField
              name="cost"
              type="text"
              inputMode="decimal"
              placeholder="0.00"
              value={typeof subscriptionData.cost === 'number' ? subscriptionData.cost.toString() : ''}
              handleChange={handleChange}
              pattern="[0-9]*[.]?[0-9]*"
            />
          </div>
        )}
        {subscriptionData.plan === SubscriptionPlan.Paid && (
          <div className={styles.formGroup}>
            <label>Currency</label>
            <SelectorField
              className={styles.currencySelector}
              name="currency"
              options={currencyOptions}
              value={subscriptionData.currency}
              handleChange={(e: LabelValue) => setSubscriptionData((s) => ({ ...s, currency: e }))}
            />
          </div>
        )}
        <div className={styles.formGroup}>
          <label>Billing Frequency</label>
          <SelectorField
            name="billing-frequency"
            options={Object.values(billingIntervals)}
            value={billingIntervals[subscriptionData.interval]}
            handleChange={(e: LabelValue) =>
              setSubscriptionData({ ...subscriptionData, interval: e.value as 'month' | 'year' })
            }
          />
        </div>
        <div className={styles.formGroup}>
          <label>Notes</label>
          <InputField
            name="note"
            placeholder="Notes"
            value={subscriptionData.note}
            handleChange={(e: any) => setSubscriptionData((s) => ({ ...s, note: e.target.value }))}
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button onClick={() => setMode(false)} type="plain" className={styles.cancelButton}>
            Cancel
          </Button>
          <Button onClick={addSubscription} type="primary" className={styles.addButton}>
            Add Subscription
          </Button>
        </div>
      </form>
    </div>
  );
};
