/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { ISubscription, ISubscriptionTotal, IUser } from 'interfaces/userInterface';
import { subscriptionsController } from 'controllers';
import { AddSubscription } from 'components/subscriptions/AddSubscription';
import { Subscription } from 'components/subscriptions/Subscription';
import styles from './SubscriptionsPage.module.scss';
import { SubscriptionsHeader } from 'components/subscriptions/SubscriptionsHeader';
import { SuggestedSubscriptions } from 'components/subscriptions/SuggestedSubscriptions';
import { Helmet } from 'react-helmet';
import { BsXLg } from 'react-icons/bs';
import { IoSettingsOutline } from 'react-icons/io5';
import { useSearchParams } from 'react-router-dom';
import DynamicIcon from 'components/SvgRender/SvgRender';
import { handleEmailIteration } from 'helpers/emailToastHelper';
import { CloneSubscription } from 'components/subscriptions/CloneSubscription';

type TabType = 'subscriptions' | 'add' | 'settings';

interface SubscriptionsPageProps {
  userData: IUser;
  reloadUser: () => void;
}

function SubscriptionsPage({ userData, reloadUser }: SubscriptionsPageProps) {
  const [searchParams] = useSearchParams();
  const emailData = useMemo(
    () => ({
      frequency: userData?.config?.subscriptions?.emailFrequency?.name,
      time: handleEmailIteration(userData?.config?.subscriptions?.emailFrequency?.sentTime),
      currency: userData?.config?.subscriptions?.preferredCurrency?.name || 'USD',
    }),
    [userData]
  );
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
  const [showSuggestedSubscriptions, setShowSuggestedSubscriptions] = useState<boolean>(false);
  const [suggestedSubscriptions, setSuggestedSubscriptions] = useState<ISubscription[]>([]);
  const [totals, setTotals] = useState<ISubscriptionTotal[]>([]);
  const [total, setTotal] = useState<string>();
  const [activeTab, setActiveTab] = useState<TabType>('subscriptions');
  const [search, setSearch] = useState<string>(searchParams.get('subApp') || '');
  const [searchResults, setSearchResults] = useState<ISubscription[]>([]);

  const getSubscriptions = async () => {
    try {
      const subscriptionsFetch = await subscriptionsController.getSubscriptions();
      if (!!subscriptionsFetch.subscriptions.length) {
        // Sort subscriptions by monthly cost
        const sortedSubscriptions = [...subscriptionsFetch.subscriptions].sort((a, b) => {
          // Convert costs to monthly basis
          const getMonthlyAmount = (sub: ISubscription) => {
            return sub.interval === 'year' ? sub.cost / 12 : sub.cost;
          };
          
          const aMonthly = getMonthlyAmount(a);
          const bMonthly = getMonthlyAmount(b);
          
          // Sort in descending order (highest first)
          return bMonthly - aMonthly;
        });
        
        setSubscriptions(sortedSubscriptions);
        setSearchResults(sortedSubscriptions);
      }
      
      const suggestedSubscriptionsFetch = await subscriptionsController.getSuggestedSubscriptions();
      setSuggestedSubscriptions(suggestedSubscriptionsFetch.subscriptions);
      
      // Only show suggested in main view if user has no subscriptions
      if (!subscriptionsFetch.subscriptions.length && !!suggestedSubscriptionsFetch.subscriptions.length) {
        setShowSuggestedSubscriptions(true);
      }

      if (
        subscriptionsFetch.totals.length === 1 &&
        subscriptionsFetch.totals[0].currency === userData.config?.subscriptions?.preferredCurrency?.id
      )
        setTotals([]);
      else setTotals(subscriptionsFetch.totals);
      setTotal(subscriptionsFetch.total);
    } catch (error: any) {
      console.error(error);
    }
  };

  const searchSubscriptions = () => {
    setSearchResults(
      subscriptions.filter((subscription) => {
        return subscription.app.title.toLowerCase().includes(search.toLowerCase());
      })
    );
  };

  useEffect(() => searchSubscriptions(), [search, searchResults.length]);

  useEffect(() => {
    getSubscriptions();
  }, [userData]);

  const handleAddSubscription = async (subData: any) => {
    try {
      await subscriptionsController.addSubscription({
        ...subData,
        currency: subData.currency?.value,
      });
      await getSubscriptions();
      setActiveTab('subscriptions');
    } catch (error: any) {
      console.error(error);
    }
  };

  return (
    <div className={styles.subscriptionsPage}>
      <Helmet>
        <title>Subscriptions - Slocco</title>
      </Helmet>

      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.totalSection}>
            <h1>{total}</h1>
            <span className={styles.subtitle}>per month for {subscriptions.length} subscriptions</span>
          </div>
        </div>
      </div>

      <div className={styles.mainContent}>
        <div className={styles.subscriptionsContainer}>
          <div className={styles.tabsContainer} role="tablist" aria-label="Subscription tabs">
            <button 
              className={`${styles.tab} ${activeTab === 'subscriptions' ? styles.active : ''}`}
              onClick={() => setActiveTab('subscriptions')}
              role="tab"
              aria-selected={activeTab === 'subscriptions'}
              aria-controls="subscriptions-panel"
              id="subscriptions-tab"
            >
              Your Subscriptions
            </button>
            <button 
              className={`${styles.tab} ${activeTab === 'add' ? styles.active : ''}`}
              onClick={() => {
                setActiveTab('add');
                setSubscriptions([...subscriptions]);
              }}
              role="tab"
              aria-selected={activeTab === 'add'}
              aria-controls="add-subscription-panel"
              id="add-subscription-tab"
            >
              Track New Subscription
            </button>
            <button 
              className={`${styles.tab} ${activeTab === 'settings' ? styles.active : ''}`}
              onClick={() => setActiveTab('settings')}
              role="tab"
              aria-selected={activeTab === 'settings'}
              aria-controls="settings-panel"
              id="settings-tab"
            >
              Settings
            </button>
          </div>
          
          <div 
            role="tabpanel"
            id="subscriptions-panel"
            aria-labelledby="subscriptions-tab"
            hidden={activeTab !== 'subscriptions'}
          >
            <div className={styles.searchSubscription}>
              <input
                type="text"
                placeholder="Search subscriptions..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {search && <BsXLg className={styles.clearIcon} onClick={() => setSearch('')} />}
            </div>

            <div className={styles.subscriptionsList}>
              {searchResults.map((subscription) => (
                <Subscription
                  key={subscription.uuid}
                  subscription={subscription}
                  getSubscriptions={getSubscriptions}
                  editable
                />
              ))}
            </div>

            {showSuggestedSubscriptions && (
              <div className={styles.popularSubscriptions}>
                <h2>Popular Subscriptions</h2>
                <div className={styles.subscriptionsList}>
                  {suggestedSubscriptions.map((subscription) => (
                    <CloneSubscription
                      key={subscription.uuid}
                      subscriptionToEdit={subscription}
                      saveSubscription={handleAddSubscription}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>

          <div 
            role="tabpanel"
            id="add-subscription-panel"
            aria-labelledby="add-subscription-tab"
            hidden={activeTab !== 'add'}
          >
            <div className={styles.addSubscriptionSection}>
              <AddSubscription
                currency={userData.config?.subscriptions?.preferredCurrency?.id || 'USD'}
                setMode={async () => {
                  await getSubscriptions();
                  setActiveTab('subscriptions');
                  setShowSuggestedSubscriptions(false);
                }}
                reload={getSubscriptions}
              />
              
              {suggestedSubscriptions.length > 0 && (
                <div className={styles.suggestedSection}>
                  <h2>Popular Subscriptions</h2>
                  <div className={styles.suggestedSubscriptions}>
                    {suggestedSubscriptions.map((subscription) => (
                      <CloneSubscription
                        userCurrency={userData.config?.subscriptions?.preferredCurrency?.id}
                        key={subscription.appId}
                        subscriptionToEdit={subscription}
                        saveSubscription={handleAddSubscription}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div 
            role="tabpanel"
            id="settings-panel"
            aria-labelledby="settings-tab"
            hidden={activeTab !== 'settings'}
            className={styles.settingsPanel}
          >
            {emailData.currency && emailData.frequency && (
              <div className={styles.emailPreferences}>
                <DynamicIcon name="email" width={20} height={20} />
                <span>
                  {emailData.frequency} updates {emailData.time && `on ${emailData.time} `}in {emailData.currency}
                </span>
              </div>
            )}
            <SubscriptionsHeader 
              reloadUser={reloadUser} 
              total={total} 
              totals={totals} 
              userData={userData} 
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionsPage;
