import React, { useState, useEffect, useRef, memo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useSnackbar } from 'notistack';

import 'styles/pages/appPage.scss';
import { appController } from 'controllers';
import { InputField, Modal } from 'components/generic';
import { AppPlatforms } from 'components/apps';
import { appInterface } from 'interfaces';
import { AddSubscription } from 'components/subscriptions/AddSubscription';
import { ISubscription, IUser } from 'interfaces/userInterface';
import { Button } from 'components/generic/Button';
import { Subscription } from 'components/subscriptions/Subscription';
import Chip from 'components/Chip';
import ExpandableText from 'components/ExpandableText/ExpandableText';
import EditAppPage from './EditAppPage';
import { Announcement } from 'components/generic/Announcement';
import DynamicIcon from 'components/SvgRender/SvgRender';

interface AppPageProps {
  isLoggedIn?: boolean;
  userData?: IUser;
}

const MemoizedAppPlatforms = memo(AppPlatforms);
const MemoizedChip = memo(Chip);
const MemoizedExpandableText = memo(ExpandableText);

const LoadingState = () => (
  <div className="loading-state">
    <h1>Loading...</h1>
  </div>
);

function AppPage({ isLoggedIn, userData }: AppPageProps) {
  const params: any = useParams();
  const navigate = useNavigate();
  const { uuid }: { uuid: string } = params;
  const { enqueueSnackbar } = useSnackbar();
  const [app, setApp] = useState<appInterface.IApp>();
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reportMessage, setReportMessage] = useState<string>('');
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showSubscriptions, setShowSubscriptions] = useState<boolean>(false);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [totalSubscriptions, setTotalSubscriptions] = useState<{ totalInPreferred: number; currency: string }>();

  async function reportApp() {
    if (!app) return enqueueSnackbar('App is not yet loaded', { variant: 'error' });
    if (!reportMessage) return enqueueSnackbar('Message is required', { variant: 'error' });
    try {
      await appController.reportApp({ uuid: app.uuid, message: reportMessage });
      return enqueueSnackbar('Your report has been sent', {
        variant: 'success',
      });
    } catch (error: any) {
      return enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  async function getAppById() {
    let appSearch: {
      app: appInterface.IApp;
      subscriptions: ISubscription[];
      total: { totalInPreferred: number; currency: string };
    };
    try {
      appSearch = await appController.getAppById(uuid);
      if (!appSearch) return navigate('/');
      setApp(appSearch.app);
      setSubscriptions(appSearch.subscriptions);
      setTotalSubscriptions(appSearch.total);
    } catch (error: any) {
      return navigate('/');
    } finally {
      return setIsLoading(false);
    }
  }

  async function addNewPlatform({ newPlatformType, newPlatformData }: appInterface.INewPlatform) {
    try {
      await appController.addNewPlatform({
        appId: uuid,
        newPlatform: { name: newPlatformType, data: newPlatformData },
      });
      getAppById();
      enqueueSnackbar('App Saved', { variant: 'success' });
    } catch (error: any) {
      enqueueSnackbar(!error?.message?.length ? error.message : 'Some error occured while adding a new platform', {
        variant: 'error',
      });
    }
  }

  useEffect(() => {
    if (!params.uuid) navigate('/');
    else getAppById();
  }, [params.uuid]);

  return isLoading || !app ? (
    <LoadingState />
  ) : (
    <>
      {app?.announcement && <Announcement type={app.announcement.type} text={app.announcement.text} />}
      <div className="appPage">
        <Helmet>
          <title>{app?.title} - Slocco</title>
        </Helmet>

        {/* Modals */}
        {showReportModal && (
          <Modal hideModal={() => setShowReportModal(false)}>
            <p style={{ fontSize: '20px' }}>Report Message</p>
            <InputField
              name="report"
              handleChange={(e) => setReportMessage(e.target.value)}
              placeholder="Report Message"
            />
            <Button
              type="primary"
              onClick={() => {
                reportApp();
                setShowReportModal(false);
              }}
            >
              REPORT
            </Button>
          </Modal>
        )}
        {showEditModal && (
          <Modal hideModal={() => setShowEditModal(false)}>
            <EditAppPage hideModal={setShowEditModal} />
          </Modal>
        )}
        {showAddModal && (
          <Modal hideModal={() => setShowAddModal(false)}>
            <AddSubscription
              reload={getAppById}
              presetApp={app}
              currency={userData?.config?.subscriptions?.preferredCurrency?.id || 'USD'}
              setMode={setShowAddModal}
            />
          </Modal>
        )}
        {showSubscriptions && (
          <Modal hideModal={() => setShowSubscriptions(false)}>
            <div className="subscriptionsList">
              {subscriptions && subscriptions.length > 0 ? (
                subscriptions.map((subscription) => (
                  <Subscription key={subscription.uuid} subscription={{ ...subscription, app }} />
                ))
              ) : (
                <div className="emptySubscriptions">
                  <h3>No Subscriptions Yet</h3>
                  <p>
                    You have not added any subscriptions for <strong>{app?.title}</strong>.
                  </p>
                  <Button
                    onClick={() => {
                      setShowSubscriptions(false);
                      setShowAddModal(true);
                    }}
                  >
                    Add Subscription
                  </Button>
                </div>
              )}
            </div>
          </Modal>
        )}

        {/* App Header Container */}
        <div className="app-header-container">
          <div className="app-info">
            <img className="appImage" referrerPolicy="no-referrer" src={app?.imageUrl} alt={app?.title} />
            <div className="app-text">
              <h1 className="title">{app?.title}</h1>
              <span className="developer">By: {app?.sellerName}</span>
            </div>
          </div>
        </div>

        {/* Platforms and Categories Grid Container */}
        <div className="platforms-categories-grid">
          {/* Platforms Container */}
          <div className="platforms-container">
            <MemoizedAppPlatforms
              platforms={app?.platforms}
              isLoggedIn={isLoggedIn}
              appName={app?.title}
              addNewPlatform={({ newPlatformType, newPlatformData }: appInterface.INewPlatform) =>
                addNewPlatform({ newPlatformType, newPlatformData })
              }
            />
          </div>

          {/* Categories Container */}
          <div className="categories-container">
            <span className="section-title">CATEGORIES AND TAGS</span>
            <div className="categories-tags">
              {app?.categories?.map((category, index) => (
                <MemoizedChip
                  key={category}
                  type="category"
                  onClick={() => navigate(`/apps?category=${encodeURIComponent(category)}`)}
                >
                  {category}
                </MemoizedChip>
              ))}
              {app?.tags?.map((tag, index) => (
                <MemoizedChip key={tag} type="tag" onClick={() => navigate(`/apps?tag=${encodeURIComponent(tag)}`)}>
                  {tag}
                </MemoizedChip>
              ))}
            </div>
          </div>

          {/* Subscription Container */}
          {isLoggedIn && (
            <div className="subscription-container">
              <span className="section-title">SUBSCRIPTIONS</span>
              <div className="subscription-info">
                {totalSubscriptions && (
                  <Button onClick={() => setShowSubscriptions(true)}>
                    See Your Total Subscriptions - {totalSubscriptions.totalInPreferred} {totalSubscriptions.currency}
                  </Button>
                )}
                <Button onClick={() => setShowAddModal(true)}>Add Subscription</Button>
              </div>
            </div>
          )}
        </div>

        {/* Screenshots Container */}
        <div className="screenshots-outer-container">
          <div className="screenshots-container">
            {app?.screenshots?.map((screenshot, index) => (
              <img key={index} className="screenshot" src={screenshot} alt={`${app.title} screenshot ${index + 1}`} />
            ))}
          </div>
        </div>

        {/* Description Container */}
        <div className="description-container">
          <MemoizedExpandableText text={app?.description || ''} />
        </div>

        {/* Actions Container */}
        {isLoggedIn && (
          <div className="actions-container">
            <div className="action-buttons">
              <Button type="danger" onClick={() => setShowReportModal(true)}>
                Report
              </Button>
              <Button onClick={() => setShowEditModal(true)}>Edit</Button>
            </div>
            <div className="external-links">
              {app?.links.map((link, i) => (
                <Button
                  key={i}
                  icon={<DynamicIcon height={18} width={18} name={link.type} />}
                  className={link.type === 'twitter' ? 'linksButton' : ''}
                  onClick={() => (window.location.href = link.link)}
                >
                  {link.type.charAt(0).toUpperCase() + link.type.slice(1)}
                </Button>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AppPage;
