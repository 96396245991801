import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'styles/components/generic/menu.scss';
import { Suggestions } from 'components/search';
import { BsXLg } from 'react-icons/bs';
import { HiMenuAlt4 } from 'react-icons/hi';
import { InnerMenuProps } from './menu';

export const MobileMenu = (props: InnerMenuProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const { logout, isLoggedIn, lists, apps } = props;
  const { search, setSearch, showSearch, setShowSearch } = props;

  const closeMenu = () => {
    setIsMenuOpen(false);
    setShowSearch(false);
    setSearch('');
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const burgerContainer = document.querySelector('.burgerContainer');
      const burger = document.querySelector('.burger');
      if (
        burgerContainer &&
        !burgerContainer.contains(event.target as Node) &&
        burger &&
        !burger.contains(event.target as Node)
      ) {
        closeMenu();
      }
    };

    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  // Prevent body scroll when menu is open
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isMenuOpen]);

  return (
    <>
      <div className="menu">
        <div className="logoBox">
          <Link className="logo" to="/" onClick={closeMenu}>
            <span className="logo-text">slocco</span>
          </Link>
        </div>

        <div className="searchBox">
          <div className="searchWrap">
            <HiMenuAlt4 
              className="widget burger" 
              onClick={(e) => {
                e.stopPropagation();
                setIsMenuOpen(true);
              }} 
            />
          </div>
        </div>
      </div>

      {isMenuOpen && <div className="overlay" onClick={closeMenu} />}
      
      <div className={`burgerContainer ${isMenuOpen ? 'active' : ''}`}>
        <div className="searchContainer">
          <input
            className="searchField"
            value={search}
            placeholder="Search Apps and Collections"
            onChange={(e) => setSearch(e.target.value)}
            onFocus={() => setShowSearch(true)}
          />
          {search.length > 0 && (
            <BsXLg 
              className="clearIcon" 
              onClick={() => setSearch('')}
            />
          )}
        </div>

        {search.length > 2 && showSearch && (!!apps.length || !!lists.length) && (
          <Suggestions
            appClicked={() => {
              setSearch('');
              closeMenu();
            }}
            lists={lists}
            apps={apps}
          />
        )}

        {isLoggedIn ? (
          <>
            <Link onClick={closeMenu} className="menuText" to="/new-app">
              Add New App
            </Link>
            <Link onClick={closeMenu} className="menuText" to="/lists/followed">
              Favourite Lists
            </Link>
            <Link onClick={closeMenu} className="menuText" to="/lists/owned">
              My Lists
            </Link>
            <Link onClick={closeMenu} className="menuText" to="/subscriptions">
              Subscriptions
            </Link>
            <Link onClick={closeMenu} className="menuText" to="/profile">
              Profile
            </Link>
            <button
              type="button"
              onClick={() => {
                closeMenu();
                logout();
              }}
              className="menuText"
              data-umami-event="Logout Mobile Menu"
            >
              Log Out
            </button>
          </>
        ) : (
          <Link onClick={closeMenu} className="menuText" to="/login" data-umami-event="Login Mobile Menu">
            Log in
          </Link>
        )}
      </div>
    </>
  );
};
